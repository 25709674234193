import { createStore } from 'vuex'

export default createStore({
  state: {
    loader:0,
    pageLoader:0,
    BaseApiURL:"https://hackathon.manialab.sa/api/",
    BaseImageURL:"https://hackathon.manialab.sa/storage/",
    allowedImages: ["jpg", "jpeg", "png"],
    allowedFiles: ["jpg", "jpeg", "png", "pdf"],
    successMsg:"",
    showSuccessModal:false,
    emailRegex: /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/,
    Eligibility_Criteria:
      [
        {
          title_en:"Age",
          text_en:"Participants must be 18 years or older.",
          title_ar:"العمر", 
          text_ar:" يجب أن يكون عمر المشاركين 18 عامًا أو أكبر.",
        },
        {
          title_en:"Residency",
          text_en:"Open to Saudi citizens only.",
          title_ar:"الإقامة", 
          text_ar:"مفتوح للمواطنين السعوديين فقط. ",

        },
        {
          title_en:"Team Composition",
          text_en:"Teams should consist a minimum of 5 members and a maximum of 6, with diverse skill sets as specified. ",
          title_ar:"تكوين الفريق", 
          text_ar:" يجب أن تتكون الفرق من5 أعضاء على الأقل و6 كحد أقصى، مع مجموعات مهارات متنوعة كما هو محدد ",

        },
        {
          title_en:"Professional Background",
          text_en:"Participants from various sectors, including finance, technology, sustainability, procurement, are encouraged to join.",
          title_ar:"الخلفية المهنية", 
          text_ar:"يتم تشجيع المشاركين من مختلف القطاعات، بما في ذلك التمويل والتكنولوجيا والاستدامة والمشتريات",

        },
        {
          title_en:"Experience Level",
          text_en:"Open to both professionals and students with relevant experience or interest in software development, budget management, or sustainability with the needed skills.",
          title_ar:"مستوى الخبرة", 
          text_ar:" مفتوح لكل من المحترفين والطلاب ذوي الخبرة ذات الصلة أو من لديهم الاهتمام والمهارات اللازمة لتطوير البرمجيات أو إدارة الميزانية أو الاستدامة.",

        },
        {
          title_en:"Commitment",
          text_en:"Participants must be available for the full duration of the challenge, including workshops, mentorship sessions, and final presentations.",
          title_ar:"الالتزام", 
          text_ar:"يجب أن يكون المشاركين متاحين  طوال مدة تحدي الإبتكار بما في ذلك ورش العمل وجلسات الإرشاد والعروض التقديمية النهائية.",

        },
        {
          title_en:"Ethics and Compliance",
          text_en:"Participants must adhere to the rules and guidelines of the hackathon, including intellectual property rights, and must conduct themselves with integrity throughout the event.",
          title_ar:"الأخلاقيات والامتثال", 
          text_ar:"يجب على المشاركين الالتزام بقواعد وإرشادات التحديّ، بما في ذلك حقوق الملكية الفكرية، ويجب أن يتصرفوا بنزاهة طوال الحدث .",

        },
      ],
    terms:[
      {
        title_en:"General Rules",
        title_ar:"القواعد العامة",
        text:[
          { 
            sub_en:"Participation Eligibility",
            sub_ar:"أهلية المشاركة",
            cont_en:"The Innovation Challenge is open to Saudi citizens aged 18 years and older. Participants must form teams of 5-6 members. ",
            cont_ar:"الهاكاثون مفتوح للمواطنين السعوديين الذين تبلغ أعمارهم 18 عامًا أو أكثر. يجب على المشاركين تشكيل فرق مكونة من 5-6 أعضاء."
          },
          {
            sub_en:"Team Formation",
            sub_ar:"تشكيل الفريق",
            cont_en:"Teams must have Business Analyst, Backend Developer, Frontend Developer, Data Scientist/AI Specialist, Procurement Specialist, and an optional Wild Card member (such as an additional developer, AI expert, or sustainability expert)",
            cont_ar:"يجب أن تضم الفرق محلل أعمال ومطورًا خلفيًا ومطورًا أماميًا وعالم بيانات/متخصصًا في الذكاء الاصطناعي وأخصائي مشتريات وعضوًا اختياريًا (مثل مطور إضافي أو خبير في الذكاء الاصطناعي أو خبير في الاستدامة .",
          },
          {
            sub_en:"Commitment",
            sub_ar:"الالتزام",
            cont_en:"All participants must be available for the full duration of Kafa'thon including the preparation phase, workshops, mentorship sessions, and final presentations.",
            cont_ar:"يجب أن يكون المشاركين متاحين  طوال مدة تحدي الإبتكار بما في ذلك ورش العمل وجلسات الإرشاد والعروض التقديمية النهائية.",
          },
          {
            sub_en:"Code of Conduct",
            sub_ar:"مدونة قواعد السلوك",
            cont_en:"Participants are expected to conduct themselves professionally and respectfully toward fellow participants, mentors, judges, and organizers. Any form of harassment, discrimination, or unethical behavior will result in disqualification.",
            cont_ar:"من المتوقع أن يتصرف المشاركون باحترافية واحترام تجاه زملائهم المشاركين، والموجهين، والحكام، والمنظمين. أي شكل من أشكال التحرش أو التمييز أو السلوك غير الأخلاقي سيؤدي إلى الاستبعاد",
          },
          {
            sub_en:"Ethics and Compliance",
            sub_ar:"الأخلاق والامتثال",
            cont_en:"Participants must adhere to intellectual property rights, data privacy regulations, and ethical AI practices. Teams must ensure their solutions are original and do not infringe on existing patents or proprietary technologies.",
            cont_ar:"يجب على المشاركين الالتزام بحقوق الملكية الفكرية ولوائح خصوصية البيانات وممارسات الذكاء الاصطناعي الأخلاقية. يجب على الفرق التأكد من أن حلولهم أصلية ولا تنتهك براءات الاختراع الحالية أو التقنيات الملكية",
          },
        ]
      },
      {
        title_en:"Intellectual Property",
        title_ar:"الملكية الفكرية",
        text:[
          {
            sub_en:"Ownership",
            sub_ar:"الملكية",
            cont_en:"Intellectual property rights (IPR) for the projects developed during Kafa'thon will be retained by the participants. However, organizers reserve the right to use and showcase the projects for promotional purposes.",
            cont_ar:"سوف يحتفظ المشاركون بحقوق الملكية الفكرية للمشاريع التي تم تطويرها خلال كفاءةثون ومع ذلك، يحتفظ المنظمون بالحق في استخدام المشاريع وعرضها لأغراض ترويجية مع الإشادة بملاك الفكرة.",
          },
          {
            sub_en:"Open-Source Componentss",
            sub_ar:"مكونات المصدر المفتوح",
            cont_en:" your project uses open-source software, ensure it complies with the applicable licenses, and credit the creators accordingly.",
            cont_ar:"إذا كان مشروعك يستخدم برنامجًا مفتوح المصدر، فيجب التأكد من امتثاله للتراخيص المعمول بها واعطاء الفضل للمبدعين طبقاً لذلك",
          },
        ]
      }

    ]
    
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
