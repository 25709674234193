<template>
  <div class="cNavy fs0" style="min-height: 100vh;">
    <div class="pf   oh bgNavy mentorSideBar fs0 " style="width: 300px;">
      <img src="@/assets/imgs/applicantBG.png" alt="" class="ov w100" style="height: 100%;" :style="{ transform: $i18n.locale === 'ar' ? 'scaleX(-1)' : 'none' }"/>
      <div class="z100 ph10" style="max-width: 100%;" >
        <img src="@/assets/imgs/logo.svg" alt=""  class="w100" style="max-width:250px;" >
      </div>
      <div class="oya h100 c mt50 sideBarInner">
        <div class="pv15">
          <router-link class="g_nm" :to="{ name: 'MentorDashboard' }">
            <div class="l mentorMenuTab g_nm" :class="{'active' : $route.name == 'MentorDashboard' }"><span class="fs24 mdi mdi-apps mt2"></span><span class="ml4 vm cap">{{ $t('dashboard') }}</span></div>
          </router-link>
        </div>
        <div class="pv15">
          <router-link class="g_nm" :to="{ name: 'Profile' }">
            <div class="l mentorMenuTab g_nm" :class="{'active' : $route.name == 'Profile' }"><span class="fs24 mdi mdi-account mt2"></span><span class="ml4 vm cap">{{ $t('profile') }}</span></div>
          </router-link>
        </div>
      </div>
      <div class="g_nm pointer loginAppBtnDark ov20 ml40 ta ra cw" @click="logoutMentor()"><span class="fs14 vm ">{{ $t('logout') }}</span><span class="fs20 mdi mdi-logout mt4 ml4" ></span></div>
      <div class="ov mt10 ml15 ra h m_sb"><span class=" pointer fs28 ba danger mdi mdi-close-thick z200" @click="closeMenu()"></span></div>
    </div>
    <div class="aplicatanForm pt40 l ph30">
      <router-view></router-view>
    </div>  
    <div v-if="$store.state.loader==1" class="z1000 pf ov bgb60">
      <div class="cc">
        <img src="@/assets/imgs/logo_new.png" alt="" class="loaderImage">
      </div>
    </div>
    <div class="ov20 z900 la ba pointer " @click="changeLang()"><span class="mdi mdi-earth fs30 cNavy vm mt2"></span> <span class="fs18 b cNavy upper vm">({{ $i18n.locale }})</span></div>
    <div class="ov20 z200 ra ba h m_sb"><span class="pointer mdi mdi-menu fs30 cNavy vm" @click="openMenu()"></span> </div>
  </div>
</template>

<script>
export default {
  name: 'MentorLayout',
  created(){
    if(this.$cookies.isKey("_HackLang")){
      if(this.$cookies.get("_HackLang")=='en'){
        this.$i18n.locale='ar';
      }
      else{
        this.$i18n.locale='en';
      }
      this.changeLang()
    }
    
  },
  
  methods: {
    logoutMentor(){
      this.$cookies.remove("_HackMenAT")
      this.$cookies.remove("_HackMenU")
      this.$router.push({ name: 'MentorLogin'});  
    },
    changeLang(){
      if(this.$i18n.locale=="en"){
        $('html').attr('lang', 'ar');  
        this.$i18n.locale='ar';
        $('body').addClass('rtl');
        const expirationTime =  100 * 60 * 60;
        this.$cookies.set("_HackLang", 'ar', expirationTime);
      }
      else{
        $('html').attr('lang', 'en');  
        this.$i18n.locale='en';
        $('body').removeClass('rtl');
        const expirationTime =  100 * 60 * 60;
        this.$cookies.set("_HackLang", 'en', expirationTime);
      }
    },
    openMenu(){
      $('.mentorSideBar').addClass('active');
    },
    closeMenu(){
      $('.mentorSideBar').removeClass('active');
    }
  }
}
</script>
