<template>
  <component :is="layout" />
</template>
<script>
import EmptyLayout from '@/Layouts/EmptyLayout.vue';
import LandingLayout from '@/Layouts/LandingLayout.vue';
import ApplicantLayout from '@/Layouts/ApplicantLayout.vue';
import ApplicantEmptyLayout from '@/Layouts/ApplicantEmptyLayout.vue';
import MentorEmptyLayout from '@/Layouts/MentorEmptyLayout.vue';
import MentorLayout from '@/Layouts/MentorLayout.vue';
import RegLayout from '@/Layouts/RegLayout.vue';

export default {
  name: "App",
  data() {
    return {
      layout: null,
    };
  },
  components: {
    EmptyLayout,
    LandingLayout,ApplicantLayout,ApplicantEmptyLayout,RegLayout,MentorEmptyLayout,MentorLayout
  },
  watch: {
    $route(to) {
      if (to.meta.layout !== undefined) {
        this.layout = to.meta.layout
      } else {
        this.layout = "EmptyLayout"
      }
    },
  },
};
</script>
