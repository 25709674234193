import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from "./i18n";
import VueAxios from "vue-axios";
import axios from "axios";
import Select2 from "vue3-select2-component";
import VueToast from 'vue-toast-notification';
import VueCookies from "vue-cookies";
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import vue3GoogleLogin from 'vue3-google-login';

import 'vue-toast-notification/dist/theme-sugar.css';
import "maz-ui/css/main.css";
import "./assets/css/bea.css";
import "./assets/css/mdi.css";
import "./assets/css/customStyle.css";

const app = createApp(App).use(store).use(router);
app.use(vue3GoogleLogin, {
  // clientId: '363169735438-rf02ja12esfntrsra22ju652upqe3bhr.apps.googleusercontent.com' //localhost
  clientId: '918883803760-ltl7u58sb2ctrhoag9jco1csp62k06s8.apps.googleusercontent.com' //online
})


app.use(i18n);
app.use(VueAxios, axios);
app.component("Select2", Select2);
app.use(VueToast);
app.use(VueCookies);
app.use(VueSweetalert2);
app.mount("#app");
