import { createI18n } from 'vue-i18n'
const i18n = createI18n({
  locale: 'en',
  messages: {
    'en': {
      invalid_link:"This link is invalid",
      Operation_Failed:"Operation Failed please try again",
      Please_fill_all_fileds:"Please fill all fileds",
      request_send_successfully:"Your request was successfully sent, \n you can send another request to diffrent team",
      ok:"OK",
      admin:"Admin",
      about_team:"About Team",
      my_team:"My Team",
      search_for_memeber:"Search for memeber in your team",
      search:"Search",  
      members:"Members",
      failed_to_get_data:"Failed to get data",
      dashboard:"dashboard",
      profile:"my profile",
      mentors:"mentors",
      feedback:"feedback",
      logout:"Logout",
      Email:"Email",
      Passowrd:"Passowrd",
      Forget_Password:"Forget Password?",
      enter_your_password:'Enter your password',
      enter_your_email:'Enter your email',
      Login:"Login",
      Enter_a_vailed_email:"Enter a vailed email",
      Log_in_successfully:"Log in successfully",
      This_account_is_not_qualified:"This account is not qualified",
      These_credentials_do_not_match_our_records:"These credentials do not match our records",
      email_is_required:"Email is required",
      password_is_required:"Password is required",
      Rest_link_send_please_check_your_email:"Rest link send please check your email",
      Join_a_team:"Join a team",
      Create_a_team:"Create a team",
      Back:"Back",
      create_team:"Create Team",
      create:"Create",
      Check_Your_Tags:"Check Your Tags",
      turn_on_other_people_will_see_your_team_and_they_can_join_to_it:"turn on other people will see your team and they can join to it",
      The_name_has_already_been_taken:"The name has already been taken",
      Team_created_successfully:"Team created successfully",
      Team_name_required:"Team_name required",
      Team_english_description_required:"Team english description required",
      Team_arabic_description_required:"Team arabic description required",
      Please_select_at_least_on_tag:"Please select at least one tag",
      Team_Name:"Team Name",
      Team_Description_EN:"Team Description EN",
      Team_Description_Ar:"Team Description Ar",
      Search_For_team:"Search For team",
      Send_Request:"+ Send Request",
      Search:"Search",
      no_result:"Sorry no result found",
      request:"Request",
      member:"members",
      see_more:"See more",
      phone:"Phone",
      email:"Email",
      university:"University",
      stream:"Stream",
      graduation_year:"Graduation Year",
      degree:"Degree",
      experience:"Experience",
      years:"Years",
      skill:"Skill",
      request_rejected:"Rejected",
      request_accepted:"Accepted",
      reject:"Reject",
      accept:"Accept",
      request_list_updated_successfully:"Request list updated successfully",
      request_accepted_successfully:"Request accepted successfully",
      failed_accept_request:"Failed accept request",
      request_rejected_successfully:"Request rejected successfully",
      failed_reject_request:"Failed reject request",
      failed_to_get_request:"Failed to get request",
      cancel_Request:"Cancel Request",
      request_canceled_successfully:"Request canceled successfully",
      New_Password:"New Password",
      enter_your_new_passowrd:"Enter your new passowrd",
      Confirm_Password:"Confirm Passord",
      Chagne_Password:"Change Password",
      password_should_be_at_least_8_char:"Password should be at least 8 char",
      Confirm_password_is_required:"Confirm password is required",
      password_dosenot_match:"Passwords doesn't match",
      welcome_to_expro:"Welcome to EXPRO",
      Personal_Inforamtion:"Personal Inforamtion",
      Skilles:"Skilles",
      Education:"Education",
      First_Name:"First Name",
      Last_Name:"Last Name",
      Phone_Number:"Phone Number",
      Nationality:"Nationality",
      ID:"ID",
      zip_code:"Zip Code",
      Address:"Address",
      next:"Next",
      First_name_is_required:"First name is required",
      Last_name_is_required:"Last name is required",
      Phone_is_required:"Phone is required",
      Zip_cod_is_required:"Zip cod is required",
      Address_is_required:"Address is required",
      Nationality_is_required:"Nationality is required",
      ID_number_is_required:"ID number is required",
      invalid_id: "Please enter a correct ID number",
      invalid_phone: "Please enter a valid phone number",
      check_Your_Skills:"Check your skills",
      Please_selsect_at_least_one_skill:"Please selsect at least one skill",
      Upload_resume:"Upload resume",
      or_just_drag_and_drop:"or just drag and drop",
      University:"University",
      University_Degree:"University Degree",
      Stream:"Stream",
      Graduation_year:"Graduation year",
      Year_Of_Experince:"Number of years of experience",
      submit:"Submit",
      cv:"Upload CV",
      CV_is_required:"CV is required",
      University_name_is_required:"University name is required",
      Degree_is_required:"University Degree is required",
      Stream_is_required:"Stream is required",
      Graduation_year_is_required:"Graduation year is required",
      Year_of_experince_is_required:"Year of experience is required",
      Registration_Success:"Registration Success",
      The_email_has_already_been_taken:"The email has already been taken",
      File_is_too_large:"File is larger than 6MB",
      Supported_files_fromat_are:"Supported files fromat are",
      sector:"Sector",
      sector_is_required:"Sector is required",
      rounds:"Rounds",
      apply:"Apply Now",
      this_field_is_required:"This field is required",
      text_length_should_be_between:"Text length should be between",
      number_should_be_between:"Number should be between",
      date_should_be_between:"Date should be between",
      email_not_found:"Email not found",
      send_reset_link:'Send reset link',
      field:"Field",
      Book_Now:"Book Now",
      please_Select_Session:"Please select session to book",
      session_booked_successfully:"Session booked successfully",
      failed_to_book_session:"Failed to book session",
      Book_a_session:"Book a session",
      no_available_sessions:"Sorry, no available sessions",
      Search_For_Mentor:"Search for mentor",
      my_session:"My Sessions",
      failed_to_get_sessions:"Failed to get sessions",
      session_list_updated_successfully:"Session list updated successfully",
      mentor_list_updated_successfully:"Mentor list updated successfully",
      no_booked_sessions:"Sorry, no booked sessions",
      mentor_name:"mentor name",
      date:"Date",
      time:"Time",
      download_file:"Download File",
      no_uploaded_file:"No Uploaded File",
      download_image:"Download Image",
      no_uploaded_image:"No Uploaded Image",
      no_feedback:"Sorry, there is no feedback",
      register_now:"register now",
      about_expro:"About EXPRO",
      explore_kafa:"Explore Kafa'athon",
      faqs:"FAQs",
      event_start:"Kafa’athon 2024 Kicks Off in….",
      about_q1:'What is the Efficiency and Government Projects Authority (EXPRO)?',
      about_a1:"EXPRO was formed pursuant to the Cabinet Resolution issued on February 23, 2021 corresponding to Rajab 11, 1442 AH, pursuant to Resolution No. (389) , which stipulates the inclusion of the National Program for Supporting Project Management, Operation and Maintenance in Public Entities (Projects) in the Center for Achieving Spending Efficiency, and transforming the center into the Authority for Spending Efficiency and Government Projects.",
      about_q2:"The Purpose of Establishing the Authority:",
      about_a2_1:"Contributing to achieving spending efficiency in government agencies",
      about_a2_2:"Improving the quality of projects, assets and facilities, infrastructure planning, programmes, initiatives and operational processes funded by the state’s general budget",
      about_a2_3:"Follow up on the implementation of these entities’ programmes and initiatives in a manner that achieves the authority’s objectives.",
      explore_t1:"AI Financial Forecasting and Analysis Solution",
      challenge:"challenge",
      explore_t2:"Develop a software solution that enables relevant entities to accurately calculate, forecast and allocate budgets to upcoming projects or RFPs. The solution should streamline budget management, integrate sustainability metrics for resource re-use, and incorporate a budget variance tool to drive continuous improvement. The tool must enhance decision-making, promote resource efficiency, and support sustainable project planning.",
      Create_session:"Create Session",
      team_name:"Team Name",
      session_time:"Session Time",
      status_action:"Status / Action",
      no_session:"Sorry, there is no sessions",
      status:"Status",
      are_you_sure_you_want_to_cancel_this_session:"Are you sure you want to cancel this session",
      are_you_sure_you_want_to_delete_this_session:"Are you sure you want to delete this session",
      yes:"Yes",
      no:"No",
      session_no:"Session number",
      session_canceled_successfully:"Session canceled successfully",
      session_cancel_failed:"Session cancel failed",
      session_date:"Session Date",
      date_is_required:"Date is required",
      start_time_is_required:"Start time is required",
      end_time_is_required:"End time is required",
      session_start_time:"Session start time",
      session_end:"Session end time",
      end_time_should_be_larger:"Session end time should be larger then session start time by at least 10 min",
      session_created_successfully:"Session created successfully",
      failed_to_crearte_sesssion:"Failed to crearte sesssion",
      delete:"delete",
      session_deleted_successfully:"Session deleted successfully",
      session_deleted_failed:"Failed to delete session",
      healthcare:"healthcare",
      construction:"construction",
      event_and_coferencese:"Event and coferencese",
      Industries_In_Focus:"Industries In Focus",
      step_1_text:"Register for Kafa’athon 2024.",
      step_2_text:"Join or Form a Team",
      step_3_text:"Submit Your Idea",
      step_4_text:"Initial Filtration",
      step_5_text:"Kafa’thon LIVE",
      step_1_date:"10 October",
      step_2_date:"11 October",
      step_3_date:"13 October",
      step_4_date:"14 October",
      step_5_date:"11th-12th of November",
      judges:"judges",
      faq:"FAQs",
      sponsers:"sponsers",
      day:"Day",
      hours:"Hours",
      minutes:"minutes",
      seconds:"seconds",
      contact_us:"contact us",
      Continue_with_email:"Continue with email",
      cor:"Country of residence",
      cor_is_required:"Country of residence is required",
      full_name_is_required:"Full name is required",
      full_Name:"Full Name",
      jobTitle:"Current Job Title",
      jobTitle_is_required:"Job Title is required",
      terms_label:"By continuing, you agree to Hacker Earth's Privacy Policy and agree to our Terms of Service.",
      click_to_Read:"Click to read more about EXPRO",
      see_less:"see less",
      Eligibility_Criteria:"Eligibility Criteria",
      accetp_terms:"You need to accept tterms and conditions to continue registration",
      step:"step",
      partners:"Partners",
      what_next:"WHAT’S NEXT?",
      dob:"Date of Birth",
      dob_is_required:"Date of Birth is required",
      skills_exprtise:"Skills Exprtise",
      lang_prof:"Language Proficiency",
      lang_prof_is_required:"Language Proficiency is required",
      educational_level:"Educational Level",
      educational_level_is_required:"Educational Level is required",
      last_attended_university:"Last attended university",
      last_attended_university_is_required:"Last attended university is required",
      
    },
    'ar': {
      invalid_link:"هذا الرابط غير صحيح",
      Operation_Failed:"فشلت المحاولة الرجاء إعادة المحاولة",
      Please_fill_all_fileds:"الرجاء إدخال جميع الحقول",
      request_send_successfully:"لقد تم إرسال طلبك بنجاح، \n بإمكانك إرسال طلب جديد لفريق أخر",
      ok:"حسناً",
      admin:"أدمن",
      about_team:"نبذة عن الفريق",
      my_team:"فريقي",
      search_for_memeber:"إبحث عن عضو في فريقك",
      search:"بحث",  
      members:"أعضاء الفريق",
      failed_to_get_data:"فشل الحصول على البيانات",
      dashboard:"لوحة التحكم",
      profile:"حسابي الشخصي",
      mentors:"المرشدين",
      feedback:"الملاحظات",
      logout:"تسجيل خروج",
      Email:"البريد الإلكتروني",
      Passowrd:"كلمة المرور",
      Forget_Password:"نسيت كلمة المرور؟",
      enter_your_password:'كلمة المرور',
      enter_your_email:'البريد الإلكتروني',
      Login:"تسجيل دخول",
      Enter_a_vailed_email:"الرجاء التأكد من صحة البريد الإلكتروني",
      Logged_in_successfully:"تم تسجيل الدخول بنجاح",
      This_account_is_not_qualified:"هذا الحساب لم يتأهل",
      These_credentials_do_not_match_our_records:"البريد الإلكتروني وكلمة المرور غير متطابقين",
      email_is_required:"الرجاء إدخال البريد الإلكتروني",
      password_is_required:"الرجاء إدخال كلمة المرور",
      Rest_link_send_please_check_your_email:"تم إرسال رابط إعادة تعيين كلمة المرور إلى بريدك الإلكتروني",
      Join_a_team:"الإنضمام إلى فريق",
      Create_a_team:"إنشاء فريق",
      Back:"رجوع",
      create_team:"إنشاء فريق",
      create:"إنشاء",
      Check_Your_Tags:"إختر المجالات التي تهمك",
      turn_on_other_people_will_see_your_team_and_they_can_join_to_it:"قم بتفعيلها إذا أردت أن يتمكن الأخرون من الإنضمام إلى فريقك",
      The_name_has_already_been_taken:"هذا الاسم قد تم إختياره من قبل",
      Team_created_successfully:"تم إنشاء الفريق بنجاح",
      Team_name_required:"الرجاء إدخال اسم الفريق",
      Team_english_description_required:"الرجاء إدخال وصف الفريق باللغة الأجنبية",
      Team_arabic_description_required:"الرجاء إدخال وصف الفريق باللغة العربية",
      Please_select_at_least_on_tag:"الرجاء إختيار مجال واحد على الأقل",
      Team_Name:"اسم الفريق",
      Team_Description_EN:"وصف الفريق باللغة الأجنبية",
      Team_Description_Ar:"وصف الفريق باللغة العربية",
      Search_For_team:"ابحث عن فريق",
      Send_Request:"+ طلب إنضمام",
      Search:"بحث",
      no_result:"عذراً لم يتم العثور على أي نتائج",
      request:"الطلبات",
      memeber:"أعضاء الفريق",
      see_more:"المزيد",
      phone:"رقم الهاتف",
      email:"البريج الإلكتروني",
      university:"الجامعة",
      stream:"التخصص",
      graduation_year:"سنة التخرج",
      degree:"المستوى التعليمي",
      experience:"سنوات الخبرة",
      years:"سنة",
      skill:"المهارات",
      request_rejected:"مرفوض",
      request_accepted:"مقبول",
      reject:"رفض",
      accept:"قبول",
      request_list_updated_successfully:"تم تحديث قائمة الطلبات بنجاح",
      request_accepted_successfully:"تم قبول الطلب بنجاح",
      failed_accept_request:"فشل قبول الطلب الرجاء إعادة المحاولة",
      request_rejected_successfully:"تم رفض الطلب بنجاح",
      failed_reject_request:"فشل رفض الطلب الرجاء إعادة المحاولة",
      failed_to_get_request:"فشل الحصول على الطلبات الرجاء إعادة المحاولة",
      cancel_Request:"إلغاء الطلب",
      request_canceled_successfully:"تم إلفاء الطلب بنجاح",
      New_Password:"كلمة المرور الجديدة",
      enter_your_new_passowrd:"كلمة المرور الجديدة",
      Confirm_Password:"تأكيد كلمة المرور",
      Chagne_Password:"تغير كلمة المرور",
      password_should_be_at_least_8_char:"يجب أن تتكون كلمة المرور من ثمانية أحرف على الأقل",
      Confirm_password_is_required:"الرجاء تأكيد كلمة المرور",
      password_dosenot_match:"كلمة المرور غير متطابقة",
      welcome_to_expro:"أهلا بكم في EXPRO",
      Personal_Inforamtion:"المعلومات الشخصية",
      Skilles:"المهارات",
      Education:"التعليم",
      First_Name:"الاسم الأول",
      Last_Name:"الاسم الثاني",
      Phone_Number:"رقم الهاتف",
      Nationality:"الجنسية",
      ID:"رقم الهوية",
      zip_code:"رمز البريد",
      Address:"العنوان",
      next:"التالي",
      First_name_is_required:"الرجاء إدخال الاسم الأول",
      Last_name_is_required:"الرجاء إدخال الاسم الثاني",
      Phone_is_required:"الرجاء إدخال رقم الهاتف",
      Zip_cod_is_required:"الرجاء إدخال الرمز البريدي",
      Address_is_required:"الرجاء إدخال العنوان",
      Nationality_is_required:"الرجاء إدخال الجنسية",
      ID_number_is_required:"الرجاء إدخال رقم الهوية",
      invalid_id: "الرجاء إدخال رقم الهوية الصحيح",
      invalid_phone: "الرجاء إدخال رقم هاتف صحيح",
      check_Your_Skills:"اختر مهاراتك",
      Please_selsect_at_least_one_skill:"الرجاء إختيار مهارة واحدة على الأقل",
      Upload_resume:"تحميل السيرة الذاتية",
      or_just_drag_and_drop:"أو ضعها هنا",
      University:"الجامعة",
      University_Degree:"الشهادة الجامعية",
      Stream:"التخصص",
      Graduation_year:"سنة التخرج",
      Year_Of_Experince:"عدد سنوات الخبرة",
      submit:"إرسال",
      CV_is_required:"الرجاء تحميل السيرة الذاتية",
      University_name_is_required:"الرجاء إدخال اسم الجامعة",
      Degree_is_required:"الرجاء إدخال الشهادة الجامعية",
      Stream_is_required:"الرجاء إدخال التخصص",
      Graduation_year_is_required:"الرجاء إدخال سنة التخرج",
      Year_of_experince_is_required:"الرجاء إدخال عدد سنوات الخبرة",
      Registration_Success:"تم التسجيل بنجاح",
      The_email_has_already_been_taken:"هذا البريد الإلركتروني مسجل مسبقا",
      File_is_too_large:"حجم الملف أكبر من 6MB",
      Supported_files_fromat_are:"صغية الملفات المدعومة: ",
      sector:"القطاع",
      sector_is_required:"الرجاء إختيار القطاع",
      rounds:"الجولات",
      apply:"قدم الآن",
      this_field_is_required:"هذا الحقل إجباري",
      text_length_should_be_between:"يجب أن يكون النص بين",
      number_should_be_between:"يجب أن يكون الرقم بين",
      date_should_be_between:"يجب ان يكون التاريخ بين",
      email_not_found:"البريد الإلكتروني غير موجود",
      send_reset_link:'إرسال رابط إعادة التعيين',
      field:"المجال",
      Book_Now:"احجز الآن",
      please_Select_Session:"الرجاء إختيار وقت لحجز جلسة",
      session_booked_successfully:"تم حجظ الجلسة بنجاح",
      failed_to_book_session:"فشل حجز الجلسة",
      Book_a_session:"احجز جلسة",
      no_available_sessions:"عذراً, لا يوجد جلسات متوفرة",
      Search_For_Mentor:"إبحث عن مرشد",
      my_session:"جلساتي",
      failed_to_get_sessions:"فشل الحصول على الجلسات",
      session_list_updated_successfully:"تم تحديث قائمة الجلسات بنجاح",
      mentor_list_updated_successfully:"تم تحديث قائمة المرشدين بنجاح",
      no_booked_sessions:"عذراً, لا يوجد جلسات محجوزة",
      mentor_name:"اسم المرشد",
      date:"التاريخ",
      time:"الوقت",
      download_file:"تحميل الملف",
      no_uploaded_file:"لم يتم تحميل ملف",
      download_image:"تحميل الصورة",
      no_uploaded_image:"لم يتم تحميل صورة",
      no_feedback:"عذراً، لا يوجد ملاحظات",
      register_now:"سجل الآن",
      about_expro:"نبذة عن EXPRO",
      explore_kafa:"تعرف على كفاءة ثون",
      faqs:"الأسئلة الشائعة",
      
      event_start:"إنطلاق فعاليات كفاءات 2024 في ....",
      about_q1:'What is the Efficiency and Government Projects Authority (EXPRO)?',
      about_a1:"EXPRO was formed pursuant to the Cabinet Resolution issued on February 23, 2021 corresponding to Rajab 11, 1442 AH, pursuant to Resolution No. (389) , which stipulates the inclusion of the National Program for Supporting Project Management, Operation and Maintenance in Public Entities (Projects) in the Center for Achieving Spending Efficiency, and transforming the center into the Authority for Spending Efficiency and Government Projects.",
      about_q2:"The Purpose of Establishing the Authority:",
      about_a2_1:"Contributing to achieving spending efficiency in government agencies",
      about_a2_2:"Improving the quality of projects, assets and facilities, infrastructure planning, programmes, initiatives and operational processes funded by the state’s general budget",
      about_a2_3:"Follow up on the implementation of these entities’ programmes and initiatives in a manner that achieves the authority’s objectives.",
      explore_t1:"AI Financial Forecasting and Analysis Solution",
      challenge:"challenge",
      explore_t2:"Develop a software solution that enables relevant entities to accurately calculate, forecast and allocate budgets to upcoming projects or RFPs. The solution should streamline budget management, integrate sustainability metrics for resource re-use, and incorporate a budget variance tool to drive continuous improvement. The tool must enhance decision-making, promote resource efficiency, and support sustainable project planning.",
      Create_session:"إضافة جلسة",
      team_name:"اسم الفريق",
      session_time:"وقت الجلسة",
      status_action:"الحالة/الإجراء",
      no_session:"عذراً لا يوجد جلسات",
      status:"الحالة",
      are_you_sure_you_want_to_cancel_this_session:"هل أنت متأكد من إلغاء هذه الجلسة",
      are_you_sure_you_want_to_delete_this_session:"هل أنت متأكد من حذف هذه الجلسة",
      yes:"نعم",
      no:"كلا",
      session_no:"الجلسة رقم",
      session_canceled_successfully:"تم إلفاء الجلسة بنجاح",
      session_cancel_failed:"فشل إلغاء الجلسة",
      session_date:"تاريخ الجلسة",
      date_is_required:"الرجاء إدخال تاريخ الجلسة",
      start_time_is_required:"الرجاء إدخال وقت بدء الجلسة",
      end_time_is_required:"الرجاء إدخال وقت إنتهاء الجلسة",
      session_start_time:"Session start time",
      session_end:"Session end time",
      end_time_should_be_larger:"يجب أن يكون وقت إنتهاء الجلسة أكبر ب 10 دقائق على الأقل من وقت بدأ الجلسة",
      session_created_successfully:"تم إضافة الجلسسة بنجاح",
      failed_to_crearte_sesssion:"فشل إضافةالجلسة",
      delete:"حذف",
      session_deleted_successfully:"تم حذف الجلسة بنجاح",
      session_deleted_failed:"فشل حذف الجلسة",
      healthcare:"رعاية طبية",
      construction:"البناء",
      event_and_coferencese:"فعاليات ومؤتمرات",
      Industries_In_Focus:"الصناعات المستهدفة",
      judges:"الحكام",      
      faq:"الأسئلة الشائعة",
      sponsers:"الرعاة",
      day:"يوم",
      hours:"ساعات",
      minutes:"دقائق",
      seconds:"ثواني",
      contact_us:"تواصل معنا",
      Continue_with_email:"تابع ببريدك الإلكتروني",
      cor:"بلد الإقامة",
      cor_is_required:"الرجاء إدخال بلد الإقامة",
      full_name_is_required:"الرجاء إدخال الاسم الكامل",
      full_Name:"الاسم الكامل",
      jobTitle:"المسمى الوظيفي الحالي",
      jobTitle_is_required:"الرجاء إدخال االمسمى الوظيفي",
      terms_label:"بإقرارك على الاستمرار، فأنت توافق على سياسة السرية لهاكر ايرث وتوافق على شروط خدمتنا ",
      click_to_Read:"انقر للذهاب لموقع الهيئة",
      see_less:"إخفاء",
      Eligibility_Criteria:"معاير الأهلية",
      accetp_terms:"يجب عليك قبول القواعد والشروط لتتمكن من التسجيل",
      step:"الخطوة",
      partners:"الشركاء",
      what_next:"ما هو التالي؟",
      dob:"تاريخ الميلاد",
      cv:"السيرة الذاتية المحدثة",
      dob_is_required:"الرجاء إدخال تاريخ الميلادس",
      skills_exprtise:"المهارات والخبرات",
      lang_prof:"إجادة اللغة",
      lang_prof_is_required:"الرجاء إختيار إجادة اللغة",
      educational_level:"المستوى التعليمي",
      educational_level_is_required:"الرجاء إدخال المستوى التعليمي",
      last_attended_university:"اخر جامعة التحقت بها",
      last_attended_university_is_required:"الرجاء اخر جامعة التحقت بها",
      'The Rules and Guidelines':"القواعد والإرشادات",
      
      
      
      

    },
  },
})
export default i18n
