import { createRouter, createWebHistory } from 'vue-router'
const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import( '../views/HomeView.vue'),
    meta: {
      layout: "LandingLayout",
    },
  },
  {
    path: '/registration',
    // component: Parent,
    children: [
      {
        path: 'step0',
        name:'ApplicantStep0',
        component: () => import( '../views/Applicant/Registration/Nafath.vue'),
      },
      {
        path: 'step1',
        name:'ApplicantStep1',
        meta:{
          layout: "RegLayout"
        },
        component: () => import( '../views/Applicant/Registration/Step1.vue'),
      },
      {
        path: 'step2',
        name:'ApplicantStep2',
        meta:{
          layout: "RegLayout"
        },
        component: () => import( '../views/Applicant/Registration/Step2.vue'),
      },
      {
        path: 'step3',
        name:'ApplicantStep3',
        meta:{
          layout: "RegLayout"
        },
        component: () => import( '../views/Applicant/Registration/Step3.vue'),
      },
      {
        path: 'thankyou',
        name: 'ApplicantThankyou',
        component: () => import( '../views/Applicant/Registration/ThankYou.vue'),
      },
     
    ]
  },
  {
    path: '/applicant',
    children: [
      {
        path:"reset-password",
        name:"ApplicantResetPassword",
        meta: {
          layout: "ApplicantEmptyLayout",
        },
        component: () => import( '../views/Applicant/Dashboard/ResetPassword.vue'),
      },
      {
        path:"login",
        name:"ApplicantLogin",
        meta: {
          layout: "ApplicantEmptyLayout",
        },
        component: () => import( '../views/Applicant/Dashboard/Login.vue'),
      },
      {
        path:"forget-password",
        name:"ApplicantForgetPassword",
        meta: {
          layout: "ApplicantEmptyLayout",
        },
        component: () => import( '../views/Applicant/Dashboard/ForgetPassword.vue'),
      },
    ]
  },
  {
    path: '/mentor',
    children: [
      {
        path:"reset-password",
        name:"MentorResetPassword",
        meta: {
          layout: "MentorEmptyLayout",
        },
        component: () => import( '../views/Mentor/Dashboard/ResetPassword.vue'),
      },
      {
        path:"login",
        name:"MentorLogin",
        meta: {
          layout: "MentorEmptyLayout",
        },
        component: () => import( '../views/Mentor/Dashboard/Login.vue'),
      },
      {
        path:"forget-password",
        name:"MentorForgetPassword",
        meta: {
          layout: "MentorEmptyLayout",
        },
        component: () => import( '../views/Mentor/Dashboard/ForgetPassword.vue'),
      },
    ]
  },
  {
    path: '/applicant',
    meta:{
      requiredMentorAuth:true,
    },
    children: [
      {
        path:"dashboard",
        name:"MentorDashboard",
        meta: {
          layout: "MentorLayout",
        },
        component: () => import( '../views/Mentor/Dashboard/Dashboard.vue'),
      },
    ]
  },
  {
    path: '/applicant',
    meta:{
      requiredApplicantAuth:true,
    },
    children: [
      {
        path:"create-or-join-team",
        name:"CreateOrJoinTeam",
        meta: {
          layout: "ApplicantEmptyLayout",
        },
        component: () => import( '../views/Applicant/Dashboard/CreateOrJoinTeam.vue'),
      },
      {
        path:"create-team",
        name:"CreateTeam",
        meta: {
          layout: "ApplicantEmptyLayout",
        },
        component: () => import( '../views/Applicant/Dashboard/CreateTeam.vue'),
      },
      {
        path:"join-team",
        name:"JoinTeam",
        meta: {
          layout: "ApplicantEmptyLayout",
        },
        component: () => import( '../views/Applicant/Dashboard/JoinTeam.vue'),
      },
      {
        path:"mentor",
        name:"BookMentor",
        meta: {
          layout: "ApplicantLayout",
        },
        component: () => import( '../views/Applicant/Dashboard/Mentor.vue'),
      },
      {
        path:"feedback",
        name:"Feedback",
        meta: {
          layout: "ApplicantLayout",
        },
        component: () => import( '../views/Applicant/Dashboard/Feedback.vue'),
      },
      {
        path:"members",
        name:"Members",
        meta: {
          layout: "ApplicantLayout",
        },
        component: () => import( '../views/Applicant/Dashboard/Members.vue'),
      },
      {
        path:"profile",
        name:"Profile",
        meta: {
          layout: "ApplicantLayout",
        },
        component: () => import( '../views/Applicant/Dashboard/Profile.vue'),
      },
      {
        path:"dashboard",
        name:"Dashboard",
        meta: {
          layout: "ApplicantLayout",
        },
        component: () => import( '../views/Applicant/Dashboard/Dashboard.vue'),
      },
    ]
  },

  {
    path: '/applicant',
    // component: Parent,
    children: [
      
    ]
  },  
  // {
  //   path: '/registration/nafath',
  //   name: 'nafath',
  //   component: () => import( '../views/Applicant/Registration/Nafath.vue'),
  // },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.meta.requiredApplicantAuth ) {
    if( $cookies.isKey("_HackAppAT") && $cookies.isKey("_HackAppU") ){

      next();
    }
    else {
      next('/applicant/login');
    }
  }
  else if(to.meta.requiredMentorAuth){
    if( $cookies.isKey("_HackMenAT") && $cookies.isKey("_HackMenU") ){

      next();
    }
    else {
      next('/mentor/login');
    }
  }
  else{ next();}

});

export default router
