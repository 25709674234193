
<template class="">
  <router-view></router-view>
  <div v-if="$store.state.loader==1" class="z1000 pf ov bgb60">
    <div class="cc">
      <img src="@/assets/imgs/logo_new.png" alt="" class="loaderImage">
    </div>
  </div>
  <div class="pf ov bgb50 z1000" v-if="$store.state.showSuccessModal">
    <div class="cc cw w80 customModal round4x sh30 ph30 pv25 m_pv10 ">
      <div class="cc w100">
          <div class="g_nm round100 p40 m_p30" style="border: 3px solid #fff;"><span class="cc fs50 m_fs40 mdi mdi-check"></span></div>
      <div class="fs30 m_fs24 b mt20">{{ $store.state.successMsg }}</div>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    name: "EmptyLayout",
    methods:{
    }
  }
</script>